export const ACTION_SET_ACTIVE_PAYMENT_SECTION = 'ACTION_SET_ACTIVE_PAYMENT_SECTION';

export const ACTION_ADD_DEPOSITS = 'ACTION_ADD_DEPOSITS';

export const ACTION_LOAD_DEPOSIT_SETTINGS__REQUEST = 'ACTION_LOAD_DEPOSIT_SETTINGS__REQUEST';
export const ACTION_LOAD_DEPOSIT_SETTINGS__SUCCESS = 'ACTION_LOAD_DEPOSIT_SETTINGS__SUCCESS';
export const ACTION_LOAD_DEPOSIT_SETTINGS__FAIL = 'ACTION_LOAD_DEPOSIT_SETTINGS__FAIL';

export const ACTION_BUY_CREDITS_LOCALLY__REQUEST = 'ACTION_BUY_CREDITS_LOCALLY__REQUEST';
export const ACTION_BUY_CREDITS_LOCALLY__SUCCESS = 'ACTION_BUY_CREDITS_LOCALLY__SUCCESS';
export const ACTION_BUY_CREDITS_LOCALLY__FAIL = 'ACTION_BUY_CREDITS_LOCALLY__FAIL';

export const ACTION_CLEAR_GATE_RESULT = 'ACTION_CLEAR_GATE_RESULT';

export const ACTION_BUY_CREDITS_EXTERNALLY__REQUEST = 'ACTION_BUY_CREDITS_EXTERNALLY__REQUEST';
export const ACTION_BUY_CREDITS_EXTERNALLY__SUCCESS = 'ACTION_BUY_CREDITS_EXTERNALLY__SUCCESS';
export const ACTION_BUY_CREDITS_EXTERNALLY__FAIL = 'ACTION_BUY_CREDITS_EXTERNALLY__FAIL';

export const ACTION_BUY_CREDITS_BY_BANK_TRANSFER__REQUEST = 'ACTION_BUY_CREDITS_BY_BANK_TRANSFER__REQUEST';
export const ACTION_BUY_CREDITS_BY_BANK_TRANSFER__SUCCESS = 'ACTION_BUY_CREDITS_BY_BANK_TRANSFER__SUCCESS';
export const ACTION_BUY_CREDITS_BY_BANK_TRANSFER__FAIL = 'ACTION_BUY_CREDITS_BY_BANK_TRANSFER__FAIL';

export const ACTION_DELETE_CREDIT_CARD__REQUEST = 'ACTION_DELETE_CREDIT_CARD__REQUEST';
export const ACTION_DELETE_CREDIT_CARD__SUCCESS = 'ACTION_DELETE_CREDIT_CARD__SUCCESS';
export const ACTION_DELETE_CREDIT_CARD__FAIL = 'ACTION_DELETE_CREDIT_CARD__FAIL';

export const ACTION_LOAD_WALLET_ADDRESS__REQUEST = 'ACTION_LOAD_WALLET_ADDRESS__REQUEST';
export const ACTION_LOAD_WALLET_ADDRESS__SUCCESS = 'ACTION_LOAD_WALLET_ADDRESS__SUCCESS';
export const ACTION_LOAD_WALLET_ADDRESS__FAIL = 'ACTION_LOAD_WALLET_ADDRESS__FAIL';

export const ACTION_CONFIRM_BANK_WIRE__REQUEST = 'ACTION_CONFIRM_BANK_WIRE__REQUEST';
export const ACTION_CONFIRM_BANK_WIRE__SUCCESS = 'ACTION_CONFIRM_BANK_WIRE__SUCCESS';
export const ACTION_CONFIRM_BANK_WIRE__FAIL = 'ACTION_CONFIRM_BANK_WIRE__FAIL';

export const ACTION_SET_IS_BANK_WIRE_WITHOUT_DETAILS_CONFIRMATION_CONFIRMED = 'ACTION_SET_IS_BANK_WIRE_WITHOUT_DETAILS_CONFIRMATION_CONFIRMED';

export const ACTION_SET_DISCOUNT_CODE = 'ACTION_SET_DISCOUNT_CODE';

export const ACTION_REQUEST_REVENUE_CONVERSION__REQUEST = 'ACTION_REQUEST_REVENUE_CONVERSION__REQUEST';
export const ACTION_REQUEST_REVENUE_CONVERSION__SUCCESS = 'ACTION_REQUEST_REVENUE_CONVERSION__SUCCESS';
export const ACTION_REQUEST_REVENUE_CONVERSION__FAIL = 'ACTION_REQUEST_REVENUE_CONVERSION__FAIL';

export const ACTION_SET_IS_REVENUE_CONVERSION_REQUEST_CONFIRMED = 'ACTION_SET_IS_REVENUE_CONVERSION_REQUEST_CONFIRMED';

export const ACTION_ADD_REWARD_REQUESTS = 'ACTION_ADD_REWARD_REQUESTS';

export const ACTION_CANCEL_REWARD_REQUEST__REQUEST = 'ACTION_CANCEL_REWARD_REQUEST__REQUEST';
export const ACTION_CANCEL_REWARD_REQUEST__SUCCESS = 'ACTION_CANCEL_REWARD_REQUEST__SUCCESS';
export const ACTION_CANCEL_REWARD_REQUEST__FAIL = 'ACTION_CANCEL_REWARD_REQUEST__FAIL';

export const ACTION_SEND_REWARD_REQUEST__REQUEST = 'ACTION_SEND_REWARD_REQUEST__REQUEST';
export const ACTION_SEND_REWARD_REQUEST__SUCCESS = 'ACTION_SEND_REWARD_REQUEST__SUCCESS';
export const ACTION_SEND_REWARD_REQUEST__FAIL = 'ACTION_SEND_REWARD_REQUEST__FAIL';

export const ACTION_LOAD_REWARD_SETTINGS__REQUEST = 'ACTION_LOAD_REWARD_SETTINGS__REQUEST';
export const ACTION_LOAD_REWARD_SETTINGS__SUCCESS = 'ACTION_LOAD_REWARD_SETTINGS__SUCCESS';
export const ACTION_LOAD_REWARD_SETTINGS__FAIL = 'ACTION_LOAD_REWARD_SETTINGS__FAIL';

export const ACTION_REPORT_DEPOSIT_ISSUE__REQUEST = 'ACTION_REPORT_DEPOSIT_ISSUE__REQUEST';
export const ACTION_REPORT_DEPOSIT_ISSUE__SUCCESS = 'ACTION_REPORT_DEPOSIT_ISSUE__SUCCESS';
export const ACTION_REPORT_DEPOSIT_ISSUE__FAIL = 'ACTION_REPORT_DEPOSIT_ISSUE__FAIL';
