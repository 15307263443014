import React, { useMemo } from 'react';
import { DropdownItem } from 'reactstrap';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import useShowModal from '../../../core/hooks/useShowModal';
import { useGetAndHandleError } from '../../../core/api/hooks';
import { useIsCommon, useIsModel } from '../../../auth/hooks';
import {
  useIsAgencySite,
  useIsAgencySiteOrModelHub,
  useIsModelHub,
  useIsRivcam,
} from '../../../initSettings/hooks';

import { l } from 'react-redux-app/lib/modules/i18n/utils';
import {
  getIndexUrl,
  getAwardsUrl,
  getHowItWorksUrl,
  getAccountSettingsUrl,
  getNotificationSettingsUrl,
  getPayoutSettingsUrl,
  getPartnersUrl,
  getPublicPhotosUrl,
  getWebcamSettingsUrl,
  getCreditsUrl,
  getRevenueUrl,
  getPurchasesUrl,
  getItemsForSaleUrl,
  getFeedbackUrl,
  getFavoritesUrl,
  getAffiliatesUrl,
  getBannedUsersUrl,
  getBlackListUrl,
  getVisitorsUrl,
  getDialogsUrl,
  getInterviewFormsUrl,
  getOwnInterviewsUrl,
  getRequestsUrl,
  getAppointmentsUrl,
  getSignupUrl,
  getNewsFeedUrl,
  getBlogSettingsUrl,
  getEmoticonShopUrl,
  getPrizesUrl,
} from '../../utils/url';

import { CONFIRMATION_DIALOG__LOGOUT } from '../../../auth/constants/confirmationDialogs';
import { DEPOSITS_TAB__SUMMARY } from '../../../deposit/constants/tabs';

import {
  getIncompleteRegistration,
  getUnreadMessagesNumber,
  getUnreadNewsFeedNumber,
  getModelRequestNumber,
  getPrizes,
  getPrizesAfterDeposit,
} from '../../../auth/selectors';
import { getLogoutError } from '../../../auth/selectors/errors';
import {
  getIsPrivateContentForbidden,
  getEmoticonShopEnabled,
  getIsWhitelabel,
} from '../../../initSettings/selectors';

import { actionShowModal } from 'react-redux-app/lib/modules/modal/actions';
import { actionShowPrizesDialog } from '../../../auth/actions/dialogs/prizesDialog';
import { actionGetCredits } from '../../../deposit/actions/getCredits';

import { Button } from 'react-redux-app/lib/modules/button/components';
import { ButtonSet } from 'react-redux-app/lib/modules/buttonSet/components';
import BlueCheckContainer from '../../../core/components/blueCheck/BlueCheckContainer';
import PinkButton from '../../../core/components/buttons/PinkButton';
import LangLink from '../../../core/i18n/components/LangLink';
import Zendesk from '../../../auth/components/Zendesk';
import DropdownItemSubmenu from '../DropdownItemSubmenu';
import HighlightedNumber from '../HighlightedNumber';

import styles from './styles/index.css.json';


export default () => {
  const prizes = useShortSelector(getPrizes);
  const prizesAfterDeposit = useShortSelector(getPrizesAfterDeposit);
  const unreadMessagesNumber = useShortSelector(getUnreadMessagesNumber);
  const unreadNewsFeedNumber = useShortSelector(getUnreadNewsFeedNumber);
  const modelRequestNumber = useShortSelector(getModelRequestNumber);
  const incompleteRegistration = useShortSelector(getIncompleteRegistration);
  const isPrivateContentForbidden = useShortSelector(getIsPrivateContentForbidden);
  const isEmoticonShopEnabled = useShortSelector(getEmoticonShopEnabled);
  const isWhitelabel = useShortSelector(getIsWhitelabel);
  const isCommon = useIsCommon();
  const isModel = useIsModel();
  const isModelHub = useIsModelHub();
  const isAgencySite = useIsAgencySite();
  const isAgencySiteOrModelHub = useIsAgencySiteOrModelHub();
  const isRivcam = useIsRivcam();

  const showModal = useShowModal();
  useGetAndHandleError(getLogoutError, 'header.log-out');

  const getItem = (to, linkValue, number = null, extraProps = {}, isBlueCheck = false) => (
    <DropdownItem tag={LangLink} to={to} {...extraProps}>
      {!isBlueCheck ? l(linkValue) : (
        <BlueCheckContainer content={l(linkValue)} blueCheckProps={{ isBlueChecked: true }} />
      )}
      {number > 0 && <HighlightedNumber number={number} />}
    </DropdownItem>
  );

  const logOutItem = useMemo(() => getItem('#', 'action.log-out', null, {
    onClick: showModal(actionShowModal(CONFIRMATION_DIALOG__LOGOUT)),
  }), []); // eslint-disable-line react-hooks/exhaustive-deps

  return isRivcam && isCommon ? (
    <>
      <DropdownItemSubmenu itemLangKey="msg.my-content">
        {getItem(getFavoritesUrl(), 'msg.favorite')}
        {getItem(getPurchasesUrl(), 'msg.purchases')}
      </DropdownItemSubmenu>
      {getItem(getDialogsUrl(), 'msg.messages', unreadMessagesNumber)}
      {getItem('#', 'msg.get-credits', null, { onClick: showModal(actionGetCredits()) })}
      <DropdownItemSubmenu itemLangKey="msg.settings">
        {getItem(getAccountSettingsUrl(), 'msg.account-settings', null, {}, isModel)}
        {getItem(getNotificationSettingsUrl(), 'msg.notification-settings')}
      </DropdownItemSubmenu>
      {getItem('#', 'msg.extra')}
      {logOutItem}
    </>
  ) : (
    <>
      {isCommon && (
        <DropdownItem tag="div">
          <ButtonSet theme={{ buttonsContainer: styles.buttons }}>
            <Button type="button" onClick={showModal(actionGetCredits())}>
              {l('action.buy-credits')}
            </Button>

            {(prizes || prizesAfterDeposit) && (
              <PinkButton
                type="button"
                onClick={() => {
                  showModal(prizesAfterDeposit ? actionGetCredits() : actionShowPrizesDialog());
                }}
              >
                {l('action.my-prizes')}
              </PinkButton>
            )}
          </ButtonSet>
        </DropdownItem>
      )}
      {isCommon && isEmoticonShopEnabled && getItem(getEmoticonShopUrl(), 'msg.emoji-shop')}
      {!isAgencySite && getItem(getNewsFeedUrl(), 'msg.news-feed', unreadNewsFeedNumber)}
      {getItem(getDialogsUrl(), 'msg.messages', unreadMessagesNumber)}
      {getItem(getAccountSettingsUrl(), 'msg.account-settings', null, {}, isModel)}
      {getItem(getNotificationSettingsUrl(), 'msg.notification-settings')}
      {!isAgencySite && getItem(getPayoutSettingsUrl(), 'msg.payout-settings')}
      {isModel && getItem(getPartnersUrl(), 'msg.my-partners')}
      {!isAgencySite && getItem(getPublicPhotosUrl(), 'msg.public-photos')}
      {isModel && getItem(getWebcamSettingsUrl(), 'msg.webcam-settings')}
      {isModel && getItem(getItemsForSaleUrl(), 'msg.items-for-sale')}
      {/* isModel && !isPrivateContentForbidden && getItem(getBlogSettingsUrl(), 'msg.blog') */}
      {isModel && getItem(getRequestsUrl(), 'msg.requests', modelRequestNumber)}
      {isModel && getItem(getOwnInterviewsUrl(), 'msg.my-interviews')}
      {isCommon && getItem(getInterviewFormsUrl(), 'msg.my-interviews')}
      {isCommon && getItem(getAppointmentsUrl(), 'msg.my-appointments')}

      <DropdownItem divider />

      {isAgencySite && getItem(getIndexUrl(), 'msg.agency')}
      {!isAgencySiteOrModelHub && getItem(getCreditsUrl(null, DEPOSITS_TAB__SUMMARY), 'msg.credit-summary')}
      {getItem(getRevenueUrl(), 'msg.revenue')}
      {!isAgencySiteOrModelHub && getItem(getPurchasesUrl(), 'msg.purchases')}
      {isCommon && getItem(getPrizesUrl(), 'msg.prize-history')}
      {!isAgencySite && getItem(getVisitorsUrl(), 'msg.visitors')}
      {!isAgencySite && getItem(getFavoritesUrl(), 'msg.favorite')}
      {!isAgencySite && getItem(getFeedbackUrl(), 'msg.feedback')}
      {isModel && getItem(getBannedUsersUrl(), 'msg.banned-users')}
      {!isAgencySite && getItem(getBlackListUrl(), 'msg.blacklist')}
      {!isAgencySite && !isWhitelabel && getItem(getAffiliatesUrl(), 'msg.affiliates')}
      {isModelHub && getItem(getAwardsUrl(), 'msg.awards')}

      <DropdownItem divider />

      {incompleteRegistration && getItem(getSignupUrl(), 'action.complete-signup')}
      {!isAgencySite && getItem(getHowItWorksUrl(), 'msg.rules')}
      <DropdownItem tag={Zendesk} className={styles.zendeskLink} />
      {logOutItem}
    </>
  );
};
