import { console } from 'react-redux-app/lib/modules/core/utils';
import { maybeHandleCriticalError } from 'react-redux-app/lib/modules/api/utils';
import { handleOnEnterException } from 'react-redux-app/lib/modules/routing/utils';

import { INTERNAL_ERROR_URI } from 'react-redux-app/lib/modules/routing/constants';

import { getLoadCountriesError } from '../../../account/selectors/errors';
import { getIsAuthenticated, getIsModel, getIsModelHub } from '../../../auth/selectors';
import { getLoadRevenueSettingsError, getLoadLatestSalesError } from '../../selectors/errors';

import { actionLoadCountriesRequest } from '../../../account/actions/loadCountries';
import { actionLoadLatestSalesRequest } from '../../actions/loadLatestSales';
import { actionLoadRevenueSettingsRequest } from '../../actions/loadRevenueSettings';


export default checkIsModelHub => ({ getState, dispatch }) => (
  function loadRevenueSettingsHandler(nextState, successCallback, errorCallback) {
    if (!getIsAuthenticated(getState()) || (checkIsModelHub && !getIsModelHub(getState()))) {
      successCallback();
      return;
    }

    const isModel = getIsModel(getState());

    Promise.all([
      dispatch(actionLoadRevenueSettingsRequest()),
      dispatch(actionLoadCountriesRequest()),
      isModel && dispatch(actionLoadLatestSalesRequest()),
    ]).then(
      () => {
        if (handleOnEnterException(getState(), errorCallback, INTERNAL_ERROR_URI)) {
          return;
        }

        // eslint-disable-next-line no-restricted-syntax, no-unreachable-loop
        for (const { globalError } of [
          getLoadRevenueSettingsError(getState()),
          getLoadCountriesError(getState()),
          getLoadLatestSalesError(getState()),
        ].filter(error => error)) {
          if (!maybeHandleCriticalError(nextState.location, globalError, errorCallback)) {
            errorCallback(INTERNAL_ERROR_URI);
          }
          return;
        }

        successCallback();
      }
    ).catch(error => {
      console.consoleError('loadRevenueSettingsHandler failed', error);
      errorCallback(INTERNAL_ERROR_URI);
    });
  }
);
