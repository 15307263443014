import { getIsModelRegistration } from '../../../auth/utils';
import { getIndexUrl } from '../../utils/url';

import { getCurrentLangForUrl } from '../../../core/i18n/selectors';
import { getIsAuthenticated, getIncompleteRegistration } from '../../../auth/selectors';


export default ({ getState, dispatch }) => (
  function modelIncompleteRegistrationRequiredHandler(nextState, successCallback, errorCallback) {
    if (
      !getIsAuthenticated(getState())
      || !getIsModelRegistration(getIncompleteRegistration(getState()))
    ) {
      errorCallback(getIndexUrl(getCurrentLangForUrl(getState())));
    } else {
      successCallback();
    }
  }
);
