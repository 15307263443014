import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useShortSelector } from 'react-redux-app/lib/modules/core/hooks';
import { useIntl } from 'react-redux-app/lib/modules/i18n/hooks';

import useShowModal from '../../../core/hooks/useShowModal';
import { useIsCommon, useIsModel, useUserCredits } from '../../../auth/hooks';
import { useIsRivcam, useIsAgencySite, useIsVideoSite } from '../../../initSettings/hooks';

import { getPublicRoomUrl } from '../../utils/url';

import { getChatCreditsThreshold } from '../../../initSettings/selectors';
import { getShowAdultWarning } from '../../selectors/adultWarning';

import { actionGetCredits } from '../../../deposit/actions/getCredits';
import { actionShowAdultWarningDialog } from '../../actions/dialogs/adultWarningDialog';


const propTypes = {
  linkComponent: PropTypes.func.isRequired,
  children: PropTypes.node,
  className: PropTypes.string.isRequired,
};

const VideochatLink = ({ linkComponent: LinkComponent, children = null, className }) => {
  const chatCreditsThreshold = useShortSelector(getChatCreditsThreshold);
  const showAdultWarning = useSelector(state => getShowAdultWarning(state));
  const isCommon = useIsCommon();
  const isModel = useIsModel();
  const credits = useUserCredits();
  const isRivcam = useIsRivcam();
  const isVideoSite = useIsVideoSite();
  const isAgencySite = useIsAgencySite();

  const { formatMessage: fm } = useIntl();

  const showModal = useShowModal();

  let action;
  if (showAdultWarning) {
    action = actionShowAdultWarningDialog();
  } else if (isCommon && credits < chatCreditsThreshold) {
    action = actionGetCredits({
      message: fm(
        { id: 'error.credits-required-for-videochat' }, { credits: chatCreditsThreshold }
      ),
    });
  }

  return !(isVideoSite || isAgencySite) && (isModel || !isRivcam) && (
    <LinkComponent
      to={getPublicRoomUrl()}
      useReactRouterLink={false}
      onClick={showModal(action)}
      className={className}
    >
      {children}
    </LinkComponent>
  );
};

VideochatLink.propTypes = propTypes;

export default VideochatLink;
