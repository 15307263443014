import useIsAgencySite from './useIsAgencySite';
import useIsModelHub from './useIsModelHub';


export default () => {
  const isAgencySite = useIsAgencySite();
  const isModelHub = useIsModelHub();

  return isAgencySite || isModelHub;
};
